<template>
  <div class="wrap">
    <div>
      <div>
        <el-form ref="form" :model="form" label-width="100px">
          <div class="form_box">
            <div>
              <el-form-item label="用户名">
                <el-input
                  v-model="form.username"
                  placeholder="请输入内容"
                  maxlength="11"
                ></el-input>
              </el-form-item>
              <el-form-item label="是否种子用户">
                <el-switch
                  active-color="#58362A"
                  v-model="is_seed"
                  active-text="种子"
                  inactive-text="非种子"
                ></el-switch>
              </el-form-item>
            </div>
            <div>
              <el-form-item label="密码">
                <el-input
                  v-model="form.password"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
              <el-form-item label="是否体验用户">
                <el-switch
                  active-color="#F3C594"
                  v-model="is_experience"
                  active-text="体验"
                  inactive-text="非体验"
                ></el-switch>
              </el-form-item>
            </div>
            <div>
              <el-form-item label="联系人">
                <el-input
                  v-model="form.contact_name"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
              <el-form-item label="是否测试用户">
                <el-switch
                  active-color="#EE984F"
                  v-model="is_test"
                  active-text="测试"
                  inactive-text="非测试"
                ></el-switch>
              </el-form-item>
            </div>
            <div>
              <el-form-item label="手机号">
                <el-input
                  v-model="form.contact_phone"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
              <el-form-item label="是否代理商">
                <el-switch
                  active-color="#58362A"
                  v-model="is_agent"
                  active-text="代理商"
                  inactive-text="非代理商"
                ></el-switch>
              </el-form-item>
            </div>
            <div>
              <el-form-item label="销售人员名字">
                <el-input
                  v-model="form.sales_personnel"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
              <el-form-item label="用户所在地区">
                <el-cascader
                  v-model="city_id"
                  :options="city"
                  @change="handlecity"
                  clearable
                ></el-cascader>
              </el-form-item>
            </div>
            <div>
              <el-form-item label="销售手机号码">
                <el-input
                  v-model="form.sales_personnel_mobile"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
              <el-form-item label="用户所在行业">
                <el-cascader
                  :props="defaultParams"
                  v-model="industry_id"
                  :options="indry"
                  @change="handleindry"
                ></el-cascader>
              </el-form-item>
            </div>
            <div>
              <el-form-item label="营业执照名称">
                <el-input
                  v-model="form.license_company_name"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
              <el-form-item label="直销、代理商">
                <el-cascader
                  v-model="agent_id"
                  :options="agemt"
                  @change="handleme"
                ></el-cascader>
              </el-form-item>
            </div>
            <div>
              <el-form-item label="营业执照地址">
                <el-input
                  v-model="form.license_company_place"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
              <el-form-item label="会员开通时间">
                <el-date-picker
                  v-model="date"
                  type="datetimerange"
                  :picker-options="pickerOptions"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  align="right"
                ></el-date-picker>
              </el-form-item>
            </div>
            <div>
              <el-form-item label="营业执照">
                <el-upload
                  class="avatar-uploader"
                  action="/api/kapin_ee/user/v1/files_upload/"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                >
                  <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                  <div v-else class="ii">
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                  </div>
                </el-upload>
              </el-form-item>
            </div>
            <div>
              <el-form-item>
                <el-button class="btn" @click="onSubmit">立即创建</el-button>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { create_user, show_agent_city } from "@/api/hr24_api/api_all.js";
import { showCity } from "@/api/me/index";
export default {
  inject: ["news"],
  data() {
    return {
      defaultParams: {
        label: "name",
        value: "industry_id",
        children: "children",
      },

      imageUrl: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      indry: [],
      city: [],
      agemt: [],
      agent_id: [],
      city_id: [],
      industry_id: [],
      date: "",
      form: {
        username: "",
        password: "",
        contact_name: "",
        contact_phone: "",
        license: "",
        license_company_name: "",
        license_company_place: "",
        sales_personnel: "",
        sales_personnel_mobile: "",
      },
      is_seed: false,
      is_experience: false,
      is_test: false,
      is_agent: false,
    };
  },
  mounted() {
    axios.get("/static/industry.json").then((res) => {
      this.indry = res;
    });
    showCity({}).then((res) => {
      console.log(res);
      // this.city = [{ label: '唐山市', value: '130200', children: [] }]
      this.city = res.data;
    });

    show_agent_city().then((res) => {
      if (res.code == 0) {
        this.agemt = res.data;
      }
    });
  },
  methods: {
    onSubmit(e) {
      let insert_data = this.form;

      insert_data.is_seed = this.is_seed == true ? 1 : 0;
      insert_data.is_experience = this.is_experience == true ? 1 : 0;
      insert_data.is_test = this.is_test == true ? 1 : 0;
      insert_data.is_agent = this.is_agent == true ? 1 : 0;
      insert_data.province_id = this.city_id[0];
      insert_data.city_id = this.city_id[1];
      insert_data.district_id = this.city_id[2];

      insert_data.industry_id = this.industry_id[1];
      insert_data.agent_id = this.agent_id[2];
      insert_data.vip_start_time = Number(this.date[0]) / 1000;
      insert_data.vip_end_time = Number(this.date[1]) / 1000;
      console.log(insert_data);

      create_user({ insert_data }).then((res) => {
        if (!res.code) {
          this.$message.success(res.msg);
          setTimeout(() => {
            this.news();
          }, 300);
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    handleAvatarSuccess(res, file) {
      this.imageUrl = file.response.file_url;
      this.form.license = file.response.file_id;
    },

    handleme(e) {
      console.log(e, this.agent_id);
    },
    handleindry(e) {
      console.log(e, this.industry_id);
    },
    handlecity(e) {
      console.log(e, this.city_id);
    },
  },
};
</script>

<style scoped lang="scss">
.wrap {
  padding-top: 54px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.el-form {
  display: flex;
  justify-content: space-around;
}
.form_box > div {
  display: flex;
}
.form_box > div .el-form-item:first-child {
  margin-right: 120px;
}

.el-range-editor.is-active,
.el-range-editor.is-active:hover {
  border-color: #784f39;
}
// .el-date-picker .el-input {
// }

.wrap > div {
  display: flex;
}
.ii {
  width: 178px;
  height: 178px;
  border: solid 1px #ccc;
  line-height: 178px;
  text-align: center;
}
</style>
